<template>
  <b-container class="mt-5">
    <b-spinner v-if="$apollo.queries.poll.loading" />
    <div v-if="poll">
      <app-page-header :title="poll.title" >
      </app-page-header>
      <div class="mb-2 mt-4">Options: </div>
      <p v-for="(item, index) in poll.options" :key="item">{{index+1}} {{ item.title }}</p>
    </div>
  </b-container>
</template>
<script>
import AppPageHeader from '@/components/general/PageHeader.vue';
import GetPoll from '@/gql/polls/GetPoll.gql';

export default {
  components: {
    AppPageHeader,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  apollo: {
    poll: {
      query: GetPoll,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },
  methods: {
  },
};
</script>
<style scoped>
</style>
