<template>
  <b-container>
    <app-page-header title="Polls" >
      <cs-button @click="showCreateModal()" corners="rounded">Create Poll</cs-button>
    </app-page-header>
    <b-spinner v-if="$apollo.queries.polls.loading" />
    <div v-for="poll of pollsSorted" :key="poll.id">
      <poll-item :poll="poll" @poll-deleted="refreshPolls" @edit-poll="openEditModal"></poll-item>
    </div>
    <app-poll-modal ref="modal" @poll-created="refreshPolls"/>
  </b-container>
</template>
<script>
import AppPageHeader from '@/components/general/PageHeader.vue';
import AppPollModal from '@/components/polls/PollModal.vue';
import PollItem from '@/components/polls/PollItem.vue';
import ListPolls from '@/gql/polls/ListPolls.gql';

export default {
  components: {
    AppPageHeader,
    AppPollModal,
    PollItem,
  },
  data() {
    return {
      initialValues: null
    };
  },
  computed: {
    pollsSorted() {
      if(!this.polls || !this.polls.length) return;
      return [...this.polls].sort((a,b)=>(new Date(b.created_at).getTime()-(new Date(a.created_at).getTime())));
    }
  },
  apollo: {
    polls: ListPolls,
  },
  methods: {
    showCreateModal(initialValues) {
      this.$refs.modal.show(initialValues);
    },
    refreshPolls() {
      this.$apollo.queries.polls.refetch();
    },
    openEditModal(poll) {
      this.showCreateModal(poll);
    }
  },
};
</script>
