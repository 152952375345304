<template>
  <b-row class="page-header">
    <b-col>
      <h1>{{title}}</h1>
      <slot name="subtitle"></slot>
    </b-col>
    <b-col>
      <div class="action-area">
        <slot></slot>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: 'Untitled Page',
    },
  },
};
</script>
<style scoped>
.action-area {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.page-header {
  margin: 20px 0px;
}
.page-header > * {
  padding: 0;
}
</style>
