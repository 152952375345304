<template>
  <vue-typeahead-bootstrap
    v-model="query"
    :data="items"
    :serializer="item => item.name"
    @input="lookup"
    @hit="select"
    :minMatchingChars="3"
    ref="typeahead"
    :disabled="disabled"
  />
</template>
<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import Typeahead from '@/gql/general/Typeahead.gql';
import TypeaheadOne from '@/gql/general/TypeaheadOne.gql';

export default {
  components: {
    VueTypeaheadBootstrap,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
    disabled: {
      value: Boolean,
      required: false,
    },
  },
  data() {
    return {
      query: '',
      items: [],
      selectedItem: null,
    };
  },
  methods: {
    async lookup () {
      if (!this.query) {
        return false;
      }
      const { data } = await this.$apollo.query({
        query: Typeahead,
        variables: {
          type: this.type,
          query: this.query,
        },
      });
      this.items = data.results;
    },
    select($event) {
      this.$emit('input', $event.id);
      return false;
    }
  },
  async mounted() {
    if (this.value && this.type) {
      console.log('Restore', this.type, this.value);
      const { data } = await this.$apollo.query({
        query: TypeaheadOne,
        variables: {
          type: this.type,
          id: this.value,
        },
      });
      this.items = [ data.item ];
      this.$refs.typeahead.inputValue = data.item.name;
    }
  },
};
</script>
