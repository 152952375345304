<template>
  <div class="goal-question">
      <h5 class="goal-question_title">
          {{question.title}}
      </h5>
      <ul>
        <li v-for="(option, index) in question.options" :key="index">{{option.title}}</li>
      </ul>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.goal-question {
  margin-top: 20px;
  background-color: var(--cs-primary-lightest);
  margin-bottom: 15px;
  padding: var(--cs-card-padding-y) var(--cs-card-padding-x);
}
</style>
