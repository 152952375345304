import Vue from 'vue';
import apolloClient from '@/services/apollo';
import VueApollo from 'vue-apollo';

import 'cs-components';
import 'cs-components/dist/csuite.css';
import './style/variables.css';
import './style/core.css';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueMoment from 'vue-moment';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueApollo);
Vue.use(VueMoment);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

new Vue({
  apolloProvider,
  router,
  render: (h) => h(App),
}).$mount('#app');
