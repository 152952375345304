import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import Login from '@/views/Login.vue';
import FeedManager from '@/views/feeds/FeedManager.vue';
import MemberManager from '@/views/members/MemberManager.vue';
import EventManager from '@/views/events/EventManager.vue';
import EventDetail from '@/views/events/EventDetail.vue';
import GoalManager from '@/views/goals/GoalManager.vue';
import PollManager from '@/views/polls/PollManager.vue';
import PollDetail from '@/views/polls/PollDetail.vue';
import InvitationManager from '@/views/members/InvitationManager.vue';
import LoggedIn from '@/views/LoggedIn.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      public: true,
    },
  },
  {
    path: '/',
    name: 'LoggedIn',
    component: LoggedIn,
    children: [
      {
        path: '',
        name: 'HomeView',
        component: HomeView,
      },
      {
        path: 'members',
        name: 'MemberManager',
        component: MemberManager,
      },
      {
        path: 'invitations',
        name: 'InvitationManager',
        component: InvitationManager,
      },
      {
        path: 'feeds',
        name: 'FeedManager',
        component: FeedManager,
      },
      {
        path: 'polls',
        name: 'PollManager',
        component: PollManager,
      },
      {
        path: 'polls/:id',
        name: 'PollDetail',
        component: PollDetail,
        props: true,
      },
      {
        path: 'events',
        name: 'EventManager',
        component: EventManager,
      },
      {
        path: 'events/:id',
        name: 'EventDetail',
        component: EventDetail,
        props: true,
      },
      {
        path: 'goals',
        name: 'GoalManager',
        component: GoalManager,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});


router.beforeEach(async (to, from, next) => {
  if (to.meta.public) return next();
  const loggedIn = localStorage.access_token;
  if (!loggedIn) {
    return next({ path: '/login' });
  }
  return next();
});


export default router;
