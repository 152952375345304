<template>
  <b-modal
    ref="modal"
    title="Create Goal"
    @hidden="resetModal"
    @ok="submitModal"
    :ok-disabled="disabled"
  >
    <form>
      <b-form-group label="Title">
        <b-form-input v-model="goalTitle" required></b-form-input>
      </b-form-group>
      <b-form-group label="Question">
        <b-form-input v-model="goalQuestion"></b-form-input>
      </b-form-group>
      <b-form-group label="Follow-up Questions">
        <div class="question" v-for="(question,k) in followUpQuestions" :key="k">
          <div class="follow-up__question">
            <form>
              <b-form-group label="Question">
                <b-form-input class="answer-input" v-model="question.title" required></b-form-input>
              </b-form-group>
              <b-form-group label="Options" class="follow-up__answers">
                <div class="answer" v-for="(option,j) in question.options" :key="j">
                  <b-form-input class="answer-input" required
                  v-model="option.title"></b-form-input>
                  <span class="answer-icons">
                    <b-icon icon="trash" variant="danger" @click="remove(k,j)"
                    @keyup.enter="remove(k,j)"
                    v-show="j || ( !j && question.options.length > 1)"></b-icon>
                    <b-icon icon="plus-circle" variant="success" @click="add(k)"
                    @keyup.enter="add(k,j)"
                    v-show="j === question.options.length-1"></b-icon>
                  </span>
                </div>
              </b-form-group>
            </form>
          </div>
          <span class="answer-icons">
            <b-icon icon="trash" variant="danger" @click="removeFollowUpQuestion(k)"
            @keyup.enter="removeFollowUpQuestion(k)"
            v-show="k || ( !k && followUpQuestions.length > 1)"></b-icon>
            <b-icon icon="plus-circle" variant="success" @click="addFollowUpQuestion(k)"
            @keyup.enter="addFollowUpQuestion(k)"
            v-show="k === followUpQuestions.length-1"></b-icon>
          </span>
        </div>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import InsertGoal from '@/gql/goals/InsertGoal.gql';
import UpdateGoal from '@/gql/goals/UpdateGoal.gql';

export default {
  data() {
    return {
      goalTitle: '',
      goalQuestion: '',
      followUpQuestions: [
        {
          title: '',
          options: [
            {
              title: '',
            },
          ],
        },
      ],
      initialValues: null,
    };
  },
  methods: {
    show(initialValues) {
      this.resetModal(initialValues);
      this.$refs.modal.show();
    },
    resetModal(initialValues) {
      this.initialValues = {...initialValues};
      if(!initialValues) {
        this.goalTitle = '';
        this.goalQuestion = '';
        this.followUpQuestions = [
          {
            title: '',
            options: [
              {
                title: '',
              },
            ],
          },
        ];
      } else {
        this.goalTitle = this.initialValues.title;
        this.goalQuestion = this.initialValues.question;
        this.followUpQuestions = this.initialValues.questions;
      }
    },
    async submitModal() {
      let result;
      if(this.initialValues && this.initialValues.id) {
        //UPDATE GOAL
        result = await this.$apollo.mutate({
          mutation: UpdateGoal,
          variables: {
            id: this.initialValues.id,
            title: this.goalTitle,
            question: this.goalQuestion,
            questions: this.groomQuestionsInputForUpdate(),
          }
        });
      } else {
        //INSERT GOAL
        result = await this.$apollo.mutate({
          mutation: InsertGoal,
          variables: {
            title: this.goalTitle,
            question: this.goalQuestion,
            questions: this.groomQuestionsInputForInsert(),
          },
        });
      }
      if (result && result.data) {
        this.resetModal();
        this.$emit('goal-added');
      } else {
        alert("There was an error saving the goal. Please try again later");
      }
    },
    addFollowUpQuestion() {
      this.followUpQuestions.push({
        title: '',
        options: [
          {
            title: '',
          },
        ],
      });
    },
    removeFollowUpQuestion(index) {
      this.followUpQuestions.splice(index, 1);
    },
    add(k) {
      this.followUpQuestions[k].options.push({ title: '' });
    },
    remove(k, j) {
      this.followUpQuestions[k].options.splice(j, 1);
    },
    groomQuestionsInputForUpdate() {
      return this.followUpQuestions
              .map(q => {
                // eslint-disable-next-line no-unused-vars
                const {__typename, ...questionWithoutTypename } = q;

                if(questionWithoutTypename && questionWithoutTypename.options) {
                  questionWithoutTypename.options = questionWithoutTypename.options.map(function(o) {
                      // eslint-disable-next-line no-unused-vars
                      const {__typename, ...optionWithoutTypename } = o;
                      return optionWithoutTypename;
                    }).filter(o => o.title && o.title.length);
                }
                
                return questionWithoutTypename;
              })
              .filter(q => q.title && q.title.length);
    },
    groomQuestionsInputForInsert() {
      return this.followUpQuestions
              .map(q => {
                q.options = q.options.filter(o => o.title && o.title.length);
                return q;
              })
              .filter(q => q.title && q.title.length);
    }
  },
  computed: {
    disabled() {
      return !this.goalQuestion || !this.goalTitle;
    },
  },
  mounted() {
    this.resetModal();
  },
};
</script>

<style scoped>
.answer-icons{
  display: flex;
  gap: 10px;
}
.question {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top:10px
}
.question >>> .follow-up__question {
  width: 85%;
}
.follow-up__question {
  border: 1px solid black;
  border-radius: 5px;
  padding: 20px;
}
.follow-up__answers .answer {
  margin-top:10px
}
.answer{
  display: flex;
  align-items: center;
  gap: 10px;
}
.answer-input {
  width: 80%;
}
</style>
