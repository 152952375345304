<template>
  <b-container>
    <b-spinner v-if="$apollo.queries.event.loading" />
    <div v-if="event">
      <app-page-header :title="event.title" >
        <div slot="subtitle">
          {{ event.starts_at | moment('LLL')}} to {{ event.ends_at | moment('LT') }}
        </div>
        <cs-button variant="secondary" corners="rounded" @click="showEventEditor()" fill="outline">Edit Event</cs-button>
        <cs-button variant="danger" corners="rounded" @click="cancelEvent()">Cancel Event</cs-button>
      </app-page-header>
      <cs-page-tabs>
        <cs-page-tab label="Basic Details">
          <b-row>
            <b-col md="4">
              <img class="event-image" :src="event.photo_url">
            </b-col>
            <b-col md="8">
              <div>
                <cs-tag v-for="tag of event.tags" :key="tag">{{ tag }}</cs-tag>
              </div>
              <p>{{ event.description }}</p>
              <p>Price: {{event.currency}} {{event.price}}</p>
              <p>Registration Link: {{ event.registration_link }}</p>
              <p>Network: {{ event.network.name }}</p>
            </b-col>
          </b-row>
        </cs-page-tab>
        <cs-page-tab label="Host">
          <p>Host: {{ event.host.name }}</p>
          <p>Organization: {{ event.organization.name }}</p>
        </cs-page-tab>
        <cs-page-tab label="Venue">
          <p>Is Online: {{ event.is_online }}</p>
          <p>City: {{ event.city.name }}</p>
          <p>Location: {{ event.location }}</p>
          <p>Venue: {{ event.venue.name }}</p>
        </cs-page-tab>
        <cs-page-tab label="Participants">
          <app-event-participants :event="event" @refresh="refresh"/>
        </cs-page-tab>
      </cs-page-tabs>
      <app-event-modal ref="modal" :edit-event="event" />
    </div>
  </b-container>
</template>
<script>
import AppPageHeader from '@/components/general/PageHeader.vue';
import GetEvent from '@/gql/events/GetEvent.gql';
import CancelEvent from '@/gql/events/CancelEvent.gql';
import AppEventModal from '@/components/events/EventModal.vue';
import AppEventParticipants from '@/components/events/EventParticipants.vue';

export default {
  components: {
    AppPageHeader,
    AppEventModal,
    AppEventParticipants,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  apollo: {
    event: {
      query: GetEvent,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },
  methods: {
    refresh() {
      console.log('Refreshing...');
      this.$apollo.queries.event.refetch();
    },
    showEventEditor() {
      // this.$refs.modal.loadEvent(this.event);
      this.$refs.modal.show();
    },
    async cancelEvent() {
      await this.$apollo.mutate({
        mutation: CancelEvent,
        variables: {
          id: this.id,
        },
      });
      this.$router.push({
        name: 'EventManager',
      });
    },
  },
};
</script>
<style scoped>
  .event-image {
    width: 100%;
  }
</style>
