import { render, staticRenderFns } from "./InvitationManager.vue?vue&type=template&id=cca66f0c&scoped=true&"
import script from "./InvitationManager.vue?vue&type=script&lang=js&"
export * from "./InvitationManager.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cca66f0c",
  null
  
)

export default component.exports