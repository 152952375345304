<template>
  <b-container>
    <div class="login-view">
      <img class="logo" src="https://www.leaders.org/images/favicon.png" alt="">
      <h1>Leaders.org Admin</h1>
      <cs-input label="Email" v-model="email" />
      <div v-if="!otpSent">
        <cs-button @click="send()" corners="rounded">Send OTP</cs-button>
      </div>
      <div v-else>
        <cs-input label="OTP" v-model="otp" />
        <div class="">
          <cs-button @click="login()">Login</cs-button>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import SendEmailOTP from '@/gql/login/SendEmailOTP.gql';
import Login from '@/gql/login/Login.gql';

export default {
  data() {
    return {
      otpSent: false,
      email: '',
      otp: '',
    };
  },
  methods: {
    async send() {
      await this.$apollo.mutate({
        mutation: SendEmailOTP,
        variables: {
          email: this.email,
        },
      });
      console.log('Sent OK');
      this.otpSent = true;
    },
    async login() {
      const resp = await this.$apollo.mutate({
        mutation: Login,
        variables: {
          email: this.email,
          otp: this.otp,
        },
      });
      console.log('Login OK', resp.data.login);
      localStorage.access_token = resp.data.login;
      this.$router.push('/');
    },
  },
};
</script>
<style lang="css" scoped>
.login-view {
  text-align: center;
  padding-top: 100px;
}
.logo {
  width: 100px;
  height: 100px;
}
input {
  width: 200px;
}
</style>
