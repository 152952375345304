<template>
  <div class="image-input-wrapper">
    <b-button @click="select()">Select Image</b-button>
    <div v-if="url">
      <img :src="url" alt="" class="upload-preview">
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: this.value,
    };
  },
  props: {
    value: {
      type: String,
    },
  },
  watch: {
    value() {
      this.url = this.value;
    },
  },
  methods: {
    select() {
      const fe = document.createElement('input');
      fe.type = 'file';
      fe.addEventListener('change', async () => {
        const formData = new FormData();
        formData.append('media_library_id', '127ef4f8-181d-4f8c-8298-ba06adce3b0e');
        formData.append('tags', 'direct=true');
        formData.append('name', 'directupload');
        formData.append('file', fe.files[0]);
        const response = await fetch('https://graph.efficientcloud.com/media/upload', {
          method: 'POST',
          body: formData,
        });
        const data = await response.json();
        this.url = `https://cdn.leaders.org/${data}`;
        this.$emit('input', this.url);
      });
      fe.click();
    },
  },
  mounted() {
    // this.url = this.value;
  },
};
</script>
<style scoped>
.upload-preview {
  width: 100%;
}
.image-input-wrapper {
  border: solid 1px #ccc;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 5px;
}
</style>
