<template>
  <cs-card class="poll-item">
    <div slot="body" class="poll-item__card-body">
      <div class="poll-item__top-action-bar">
        <cs-button fill="outline" corners="rounded" size="small" @click="$emit('edit-poll', poll)">Edit</cs-button>
        <cs-button variant="danger" fill="outline" corners="rounded" size="small" @click="deletepoll">Delete</cs-button>
      </div>
      <h4 class="poll-item__question">{{poll.title}}</h4>
      <ul>
      <li v-for="(option, index) in poll.options" :key="index"> 
        {{option.title}}
      </li>
      </ul>
    </div>
  </cs-card>
</template>

<script>
import DeletePoll from '@/gql/polls/DeletePoll.gql';

export default {
  props: {
    poll: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async deletepoll() {
      if(confirm('Are you sure you want to delete this poll? The action is permanent!')) {
        await this.$apollo.mutate({
          mutation: DeletePoll,
          variables: {
            pollId: this.poll.id
          },
        });
        this.$emit('poll-deleted');
      }
    }
  }
};
</script>

<style scoped>
.poll-item{
  background-color: white;
  margin-bottom: 15px; 
}
.cs-tag {
  --cs-tag-color: var(--cs-primary-lightest);
  --cs-tag-text-color: var(--cs-primary-base);
}
.poll-item__question {
  margin: 16px 0;
}
.poll-item__card-body {
  color: var(--cs-gray-07);
}
.poll-item__top-action-bar {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}
.cs-button {
  margin-left: 16px;
}
</style>
