import 'isomorphic-unfetch';
import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';

export default new ApolloClient({
  cache: new InMemoryCache(),
  fetchOptions: { fetch },
  request: function(operation) {
    const token = localStorage.access_token;
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    });
  },
  uri: process.env.VUE_APP_GRAPHQL_ENDPOINT,
});
