<template>
  <b-modal
    ref="modal"
    title="Import Member"
    :ok-disabled="disabled"
    :cancel-disabled="disabled"
    @show="resetModal"
    @hidden="resetModal"
    @ok="submitModal"
  >
    <form ref="form">
      <b-form-group
        label="Linkedin Url"
        invalid-feedback="Linkedin URL is required"
      >
        <b-form-input v-model="linkedinUrl" required></b-form-input>
      </b-form-group>
    </form>
  </b-modal>
</template>
<script scoped>
import ImportPerson from '@/gql/members/ImportPerson.gql';

export default {
  data() {
    return {
      linkedinUrl: null,
      disabled: false,
    };
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    resetModal() {
      this.linkedinUrl = null;
    },
    async submitModal() {
      console.log(this.linkedinUrl);
      this.disabled = true;
      try {
        await this.$apollo.mutate({
          mutation: ImportPerson,
          variables: {
            linkedinUrl: this.linkedinUrl,
          },
        });
      } catch (err) {
        alert(err.message);
      }
      this.disabled = false;
      return true;
    },
  },
};
</script>
