<template>
  <cs-card class="goal-item">
    <div slot="body" class="goal-item__card-body">
      <div class="goal-item__top-action-bar">
        <cs-tag class="goal-item__title">
            {{goal.title}}
        </cs-tag>
        <div class="goal-item__buttons">
          <cs-button fill="outline" corners="rounded" size="small" @click="$emit('edit-goal', goal)">Edit</cs-button>
          <cs-button variant="danger" fill="outline" corners="rounded" size="small" @click="deleteGoal">Delete</cs-button>
        </div>
      </div>
      <h4 class="goal-item__question">{{goal.question}}</h4>
      <goal-question v-for="(question, index) in goal.questions" :key="index" :question="question">
      </goal-question>
    </div>
  </cs-card>
</template>

<script>
import DeleteGoal from '@/gql/goals/DeleteGoal.gql';
import GoalQuestion from './GoalQuestion.vue';

export default {
  components: {
    GoalQuestion,
  },
  props: {
    goal: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async deleteGoal() {
      if(confirm('Are you sure you want to delete this goal? The action is permanent!')) {
        await this.$apollo.mutate({
          mutation: DeleteGoal,
          variables: {
            goalId: this.goal.id
          },
        });
        this.$emit('goal-deleted');
      }
    },
  }
};
</script>

<style scoped>
.goal-item{
  background-color: white;
  margin-bottom: 15px; 
}
.cs-tag {
  --cs-tag-color: var(--cs-primary-lightest);
  --cs-tag-text-color: var(--cs-primary-base);
}
.goal-item__question {
  margin: 16px 0;
}
.goal-item__card-body {
  color: var(--cs-gray-07);
}
.goal-item__top-action-bar {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.cs-button {
  margin-left: 16px;
}
</style>
