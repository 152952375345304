<template>
  <b-row>
    <b-col>
      <div>{{ invited.length }} invited</div>
      <div v-for="person of invited" :key="person.id">
        {{ person.name }}
      </div>
    </b-col>
    <b-col>
      <div v-for="member of available" :key="member.id">
        <h5>{{ member.name }}</h5>
        <div>{{ member.eventresponses.length }} events</div>
        <div v-if="member.isInvited">
          <cs-tag>Invited</cs-tag>
        </div>
        <div v-else @click="invite(member)">Invite - knows {{member.knows}}</div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import SendEventInvite from '@/gql/events/SendEventInvite.gql';

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async invite(member) {
      await this.$apollo.mutate({
        mutation: SendEventInvite,
        variables: {
          event_id: this.event.id,
          person_id: member.id,
          custom_message: '',
        },
      });
      this.$emit('refresh');
    },
  },
  computed: {
    participants() {
      return this.event.network.members.map((member) => {
        let attended = member.eventresponses.map((er) => er.event_id);
        member.isInvited = member.eventresponses.filter((er) => (er.event_id == this.event.id)).length;
        member.knows = this.event.network.members.filter((other) => {
          let otherInvited = other.eventresponses.filter((er) => (er.event_id == this.event.id)).length;
          if (!otherInvited) {
            return false;
          }
          let hasMet = false;
          for (let er of other.eventresponses) {
            if (attended.includes(er.event_id)) {
              hasMet = true;
              console.log(member.name, 'has met', other.name);
            }
          }
          return hasMet;
        }).map((other) => other.name);
        return member;
      });
    },
    invited() {
      return this.participants.filter((member) => (member.isInvited));
    },
    available() {
      return this.participants.filter((member) => (!member.isInvited));
    },
  },
};
</script>
