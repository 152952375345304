<template>
  <b-container>
    <b-jumbotron header="Feed Manager"></b-jumbotron>
    <b-spinner v-if="!feed" />
    <div v-if="feed">
      <h3>{{ feed.title }}</h3>
      <p> {{ feed.content_type }}</p>
      <a :href="feed.url"> {{ feed.url}} </a>
      <div class="mt-3 mb-3">
        <b-button variant="success" @click="rate(8)">Yes</b-button>
        <b-button variant="info" @click="rate(5)">Maybe</b-button>
        <b-button variant="danger" @click="rate(2)">No</b-button>
        <b-spinner v-if="!articles" />
      </div>
      <div v-if="articles">
        <div class="article" v-for="article of articles" :key="article.id">
          <a :href="article.link" target="_blank">
            <h4>{{ article.title }}</h4>
          </a>
          <p> {{ article.content_snippet }}</p>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
import GetPendingFeed from '@/gql/feed/GetPendingFeed.gql';
import ListFeedArticles from '@/gql/feed/ListFeedArticles.gql';
import SetFeedRating from '@/gql/feed/SetFeedRating.gql';

export default {
  data() {
    return {
      feed: null,
      articles: null,
    };
  },
  methods: {
    async loadNext() {
      this.feed = null;
      this.articles = null;
      const resp = await this.$apollo.query({
        query: GetPendingFeed,
        fetchPolicy: 'network-only',
      });
      this.feed = resp.data.feed;
      try {
        const resp2 = await this.$apollo.query({
          query: ListFeedArticles,
          variables: {
            id: this.feed.id,
          },
          fetchPolicy: 'network-only',
        });
        this.articles = resp2.data.articles;
      } catch (err) {
        alert(err.message);
      }
    },
    async rate(score) {
      const feedId = this.feed.id;
      this.feed = null;
      this.articles = null;
      await this.$apollo.mutate({
        mutation: SetFeedRating,
        variables: {
          id: feedId,
          rating: score,
        },
      });
      console.log('Sent');
      await this.loadNext();
    },
  },
  mounted() {
    this.loadNext();
  },
};
</script>
