<template>
  <b-container>
    <app-page-header title="Members">
      <b-button @click="showAddMemberModal()">Add Member</b-button>
    </app-page-header>
    <b-spinner v-if="!members" />
    <div v-if="members">
      <div v-for="member of members" :key="member.id">
        {{ member.name }}
      </div>
    </div>
    <app-add-member-modal ref="addMemberModal" />
  </b-container>
</template>
<script>
import AppPageHeader from '@/components/general/PageHeader.vue';
import AppAddMemberModal from '@/components/members/AddMemberModal.vue';
import ListMembers from '@/gql/members/ListMembers.gql';

export default {
  components: {
    AppPageHeader,
    AppAddMemberModal,
  },
  apollo: {
    members: ListMembers,
  },
  methods: {
    showAddMemberModal() {
      this.$refs.addMemberModal.show();
    },
  },
};
</script>
