<template>
  <b-container >
    <app-page-header title="Goals" >
      <cs-button @click="showCreateModal()" corners="rounded">Create Goal</cs-button>
    </app-page-header>
    <b-spinner v-if="$apollo.queries.goals.loading" />
    <app-goal-item 
      v-for="goal in goalsSorted" 
      :key="goal.id" 
      :goal="goal"
      @goal-deleted="onGoalDeleted"
      @edit-goal="openEditModal"
      />
    <app-add-goal-modal ref="modal" @goal-added="goalAdded"/>
  </b-container>
</template>

<script>
import AppPageHeader from '@/components/general/PageHeader.vue';
import AppAddGoalModal from '@/components/goals/AddGoalModal.vue';
import AppGoalItem from '@/components/goals/GoalItem.vue';
import ListGoals from '@/gql/goals/ListGoals.gql';

export default {
  components: {
    AppPageHeader,
    AppAddGoalModal,
    AppGoalItem,
  },
  apollo: {
    goals: ListGoals,
  },
  computed: {
    goalsSorted() {
      if(!this.goals || !this.goals.length) return;
      return [...this.goals].sort((a,b)=>(new Date(b.created_at).getTime()-(new Date(a.created_at).getTime())));
    }
  },
  methods: {
    showCreateModal(initialValues) {
      this.$refs.modal.show(initialValues);
    },
    goalAdded() {
      this.$apollo.queries.goals.refetch();
    },
    onGoalDeleted() {
      this.$apollo.queries.goals.refetch();
    },
    openEditModal(goal) {
      this.showCreateModal(goal);
    }
  },
};
</script>
