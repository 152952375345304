/* eslint-disable vuejs-accessibility/click-events-have-key-events */
<template>
  <b-modal
    ref="modal"
    title="Create Poll"
    :ok-disabled="disabled"
    :cancel-disabled="disabled"
    @ok="submitModal"
  >
    <form>
    <b-form-group label="Poll Title">
        <b-form-input v-model="poll_question" required></b-form-input>
      </b-form-group>
      <b-form-group v-for="(input, index) in inputs" :key="index">
        <template slot="label">Poll Option {{index+1}}</template>
        <div class="lo-input">
        <b-form-input v-model="input.title" required></b-form-input>
        <div class="lo-poll_icons">
          <i class="fa-solid fa-circle-minus"
            @click="remove(index)"
            v-if='index || (!index && inputs.length > 1)'
            aria-hidden="true"
            ></i
          >
          <i class="fa-solid fa-circle-plus"
            @click='add(index)'
            v-if='index == inputs.length - 1'
            aria-hidden="true"
            ></i
          >
        </div>
        </div>
      </b-form-group>
    </form>
  </b-modal>
</template>
<script>
import InsertPoll from '@/gql/polls/InsertPoll.gql';
import UpdatePoll from '@/gql/polls/UpdatePoll.gql';

export default {
  data() {
    return {
      inputs: [
        {
          title: '',
        },
        {
          title: '',
        },
      ],
      poll_question: '',
      disabled: false,
      initialValues: null,
    };
  },
  methods: {
    show(initialValues) {
      this.resetModal(initialValues);
      this.$refs.modal.show();
    },
    resetModal(initialValues) {
      this.initialValues = {...initialValues};
      if(!initialValues) {
        this.poll_question = '';
        this.inputs = [{ title: '' },
          {
            title: '',
          },
          {
            title: '',
          },
        ];
      } else {
        this.poll_question = initialValues.title;
        this.inputs = initialValues.options;
      }
    },
    add() {
      this.inputs.push({
        title: '',
      });
    },
    remove(index) {
      this.inputs.splice(index, 1);
    },
    async submitModal() {
      let result;
      if(this.initialValues && this.initialValues.id) {
        //UPDATE POLL
        result = await this.$apollo.mutate({
          mutation: UpdatePoll,
          variables: {
            id: this.initialValues.id,
            title: this.poll_question,
            options: this.groomOptionsInputForUpdate()
          }
        });
      } else {
        //CREATE POLL
        result = await this.$apollo.mutate({
          mutation: InsertPoll,
          variables: {
            title: this.poll_question,
            options: this.groomOptionsInputForInsert()
          }
        });
      }
      if (result && result.data) {
        this.resetModal();
        this.$emit('poll-created');
      } else {
        alert("There was an error saving the poll. Please try again later");
      }
    },
    groomOptionsInputForUpdate() {
      return this.inputs
              .map(o => {
                // eslint-disable-next-line no-unused-vars
                const {__typename, ...optionWithoutTypename } = o;
                return optionWithoutTypename;
              })
              .filter(o => o.title && o.title.length);
    },
    groomOptionsInputForInsert() {
      return this.inputs
              .map(o => o.title)
              .filter(o => !!o);
    }
  },
  mounted() {
    this.resetModal();
  },
  
};
</script>

<style scoped>
.lo-input{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5px;
    align-items: baseline;
}
.lo-poll_icons{
    display: flex;
    gap: 2px;
}
</style>
