<template>
  <b-modal
    ref="modal"
    title="Create / Edit Event"
    :ok-disabled="disabled"
    :cancel-disabled="disabled"
    @hidden="resetModal"
    @ok="submitModal"
    size="xl"
  >
    <b-row>
      <b-col>
        <b-form-group label="Picture">
          <app-image-input v-model="event.photo_url" />
        </b-form-group>
        <b-form-group label="Title">
          <b-form-input v-model="event.title" required></b-form-input>
        </b-form-group>
        <b-form-group label="Description">
          <b-form-textarea v-model="event.description" required></b-form-textarea>
        </b-form-group>
        <b-form-group label="Date">
          <b-form-datepicker v-model="temp.date" :disabled="!!editEvent"></b-form-datepicker>
        </b-form-group>
        <b-form-group label="Start Time">
          <b-form-timepicker v-model="temp.starts" :disabled="!!editEvent"></b-form-timepicker>
        </b-form-group>
        <b-form-group label="End Time">
          <b-form-timepicker v-model="temp.ends" :disabled="!!editEvent"></b-form-timepicker>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Host">
          <app-typeahead-input v-model="event.host_id" type="person"></app-typeahead-input>
        </b-form-group>
        <b-form-group label="Organization">
          <app-typeahead-input v-model="event.organization_id" type="organization"></app-typeahead-input>
        </b-form-group>
        <b-form-group label="Network" v-show="!temp.is_online">
          <app-typeahead-input v-model="event.network_id" type="network" :disabled="!!editEvent"></app-typeahead-input>
        </b-form-group>
        <b-form-group label="City" v-show="!temp.is_online">
          <app-typeahead-input v-model="event.city_id" type="city" :disabled="!!editEvent"></app-typeahead-input>
        </b-form-group>
        <b-form-group label="Venue" v-show="!temp.is_online">
          <app-typeahead-input v-model="event.venue_id" type="venue" :disabled="!!editEvent"></app-typeahead-input>
        </b-form-group>
        <!-- <b-form-group label="Location" v-show="!temp.is_online">
          <b-form-input v-model="event.location" :disabled="!!editEvent"></b-form-input>
        </b-form-group> -->
        <b-form-checkbox v-model="temp.is_online" :disabled="!!editEvent">Online Event</b-form-checkbox>
      </b-col>
      <b-col>
        <b-form-group label="Registration Link">
          <b-form-input v-model="event.registration_link"></b-form-input>
        </b-form-group>
        <b-form-group label="Currency">
          <b-form-select v-model="event.currency" :options="currencies"></b-form-select>
        </b-form-group>
        <b-form-group label="Price">
          <b-form-input v-model="event.price"></b-form-input>
        </b-form-group>
        <b-form-group label="Tags">
          <b-form-textarea v-model="temp.tags"></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import AppImageInput from '@/components/general/ImageInput.vue';
import AppTypeaheadInput from '@/components/general/TypeaheadInput.vue';
import InsertEvent from '@/gql/events/InsertEvent.gql';
import UpdateEvent from '@/gql/events/UpdateEvent.gql';

export default {
  components: {
    AppImageInput,
    AppTypeaheadInput,
  },
  props: {
    editEvent: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      event: {},
      temp: {
        tags: '',
      },
      disabled: false,
      currencies: [
        { value: 'USD', text: 'USD' },
        { value: 'SGD', text: 'SGD' },
      ],
    };
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
    loadEvent(event) {
      this.event = event;
      this.event.host_id = this.event.host.id;
      this.event.city_id = this.event.city.id;
      this.event.organization_id = this.event.organization.id;
      this.temp = {
        tags: this.event.tags.join(', '),
        date: this.event.starts_at.split('T')[0],
        starts: this.event.starts_at.split('T')[1].split('.')[0],
        ends: this.event.ends_at.split('T')[1].split('.')[0],
      };
    },
    resetModal() {
      this.event = {
        host_id: '8e21eb1f-e15e-5c23-9c6a-46c94719da36',
        city_id: '78c4f47c-6e45-5142-9d48-d67413a47e1a',
        organization_id: 'dab7fb88-e24f-5ace-8a65-4bef1f63c15a',
        network_id: '20b605ca-71b5-4483-8d1f-486eabc87b53',
        venue_id: '978ae412-67c2-4468-a590-04273ae3d43e',
      };
      this.temp = {};
    },
    async submitModal() {
      this.temp.tags = this.temp.tags || '';
      const variables = { ...this.event };
      variables.starts_at = new Date(`${this.temp.date} ${this.temp.starts}`);
      variables.ends_at = new Date(`${this.temp.date} ${this.temp.ends}`);
      variables.tags = this.temp.tags.split('\n').join(',').split(',').map((tag) => tag.trim());
      variables.price *= 1;
      variables.is_online = this.temp.is_online || false;

      if (this.event.id) {
        // Cannot be edited for events
        delete variables.city_id;
        delete variables.starts_at;
        delete variables.ends_at;
        delete variables.network_id;
        delete variables.venue_id;
        delete variables.is_online;
      }

      const resp = await this.$apollo.mutate({
        mutation: (this.event.id ? UpdateEvent : InsertEvent),
        variables: variables,
      });
      console.log('Done', resp);
    },
  },
  mounted() {
    this.resetModal();
    if (this.editEvent) {
      this.loadEvent(this.editEvent);
    }
  },
};
</script>
