<template>
  <b-container>
    <app-page-header title="Events" >
      <cs-button @click="showCreateModal()">Create Event</cs-button>
    </app-page-header>
    <cs-spinner v-if="$apollo.queries.events.loading" />
    <b-row>
      <b-col md="3">
        <cs-date-input label="From" />
        <cs-date-input label="To" />
      </b-col>
      <b-col md="9">
        <div v-for="event of events" :key="event.id">
          <router-link :to="{name:'EventDetail', params:event}">
            <h2>{{ event.title }}</h2>
          </router-link>
          <p>{{ event.starts_at | moment('LLL') }} to {{ event.ends_at | moment('LT') }}</p>
          <p>{{ event.location }}</p>
        </div>
      </b-col>
    </b-row>
    <app-event-modal ref="modal" />
  </b-container>
</template>
<script>
import AppPageHeader from '@/components/general/PageHeader.vue';
import AppEventModal from '@/components/events/EventModal.vue';
import ListEvents from '@/gql/events/ListEvents.gql';

export default {
  components: {
    AppPageHeader,
    AppEventModal,
  },
  apollo: {
    events: ListEvents,
  },
  methods: {
    showCreateModal() {
      this.$refs.modal.show();
      console.log('Show');
    },
  },
};
</script>
