<template>
  <b-container>
    <h1>Invitations</h1>
    <b-spinner v-if="$apollo.queries.person.loading" />
    <div v-if="person">
      <h3>{{ person.name }}</h3>
      <div>{{ person.title }} / {{ person.headline }}</div>
      <div>{{ person.email }}</div>
      <div>
        <a :href="person.linkedin_url" target="_blank">{{ person.linkedin_url }}</a>
      </div>
      <div v-if="website">
        <a :href="website" target="_blank">{{ website }}</a>
      </div>
      <div class="buttons">
        <b-button @click="save('queue')">Queue</b-button>
        <b-button @click="save('whiteglove')">White Glove</b-button>
        <b-button @click="save('later')">Later</b-button>
        <b-button @click="save('never')">Never</b-button>
      </div>
    </div>
  </b-container>
</template>

<script>
import GetApolloNext from '@/gql/invitations/GetApolloNext.gql';
import ReviewApollo from '@/gql/invitations/ReviewApollo.gql';

export default {
  apollo: {
    person: {
      query: GetApolloNext,
    },
  },
  computed: {
    website() {
      if (this.person && this.person.email) {
        return `https://${this.person.email.split('@')[1]}`;
      }
      return null;
    },
  },
  methods: {
    async save(status) {
      await this.$apollo.mutate({
        mutation: ReviewApollo,
        variables: {
          id: this.person.id,
          status,
        },
      });
      console.log('Saved');
      this.$apollo.queries.person.refetch();
    },
  },
};
</script>

<style lang="css" scoped>
</style>
